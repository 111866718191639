import { apiSlice } from "../../api/apiSlice";

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocales: builder.query({
      query: () => {
        return `/locales/`;
      },
      keepUnusedDataFor: 600,
      providesTags: ['Locales'],
    }),
  }),
});

export const { 
    useGetLocalesQuery
} = configApiSlice;
