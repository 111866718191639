import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  editMode: false,
  addNew: false,
  localeID: -1,
  surveyID: -1,
  surveys: [],
  refItems: null,
  translationItems: null,
}

const getsurveyLocales = (surveys, id) => {
  const survey = surveys.find(
    (item) => item.id === id
  );
  if (!survey) return []
  return survey.locales
};

const translationsSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setEditMode: {
      reducer(state, action) {
        state.editMode = action.payload;
      },
    },
    setAddNew: {
      reducer(state, action) {
        state.addNew = action.payload;
      },
    },

    setLocaleID: {
      reducer(state, action) {
        state.localeID = action.payload;
      },
    },
    setSurveys: {
      reducer(state, action) {
        state.surveys = action.payload;
      },
    },
    setRefItems: {
      reducer(state, action) {
        state.refItems = action.payload;
      },
    },
    setTranslationItems: {
      reducer(state, action) {
        state.translationItems = action.payload;
      },
    },
    setSurveyID: {
      reducer(state, action) {
        state.surveyID = action.payload;
        if (action.payload === -1) {
          state.surveyLocales = [];
        } else {
          const survey = state.surveys.find(
            (item) => item.id === action.payload
          );
          state.surveyLocales = survey ? survey.locales : [];
        }
      },
    },

  },
});

export const {
  setEditMode,
  setAddNew,
  setLocaleID,
  setSurveys,
  setSurveyID,
  setRefItems,
  setTranslationItems,
  setSurveyLocales,
  updateSurveyLocales,
} = translationsSlice.actions;

export const SelectEditMode = (state) => state.translation.editMode;
export const SelectAddNew = (state) => state.translation.addNew;
export const SelectLocaleID = (state) => state.translation.localeID;
export const SelectSurveyID = (state) => state.translation.surveyID;
export const SelectSurveyLocales = (state) => {
  return getsurveyLocales(
    state.translation.surveys, 
    state.translation.surveyID)
}

export const SelectAllSurveys = (state) => state.translation.surveys;
export const SelectAllRefitems = (state) => state.translation.refitems;
export const SelectAllTranslationItems = (state) =>
  state.translation.translationItems;

export default translationsSlice.reducer;
