import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useFireModal = (input = {}, error = false) => {
  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-2",
        cancelButton: "btn btn-secondary m-2",
      },
      buttonsStyling: false,
    })
  );

  const fireModal = (input, error) => {
    if (!error) {
      MySwal.fire({
        title: <h3>{input.title || ""}</h3>,
        html: <i>{input.message || ""}</i>,
        showCancelButton: false,
        //cancelButtonText: "Close",
        confirmButtonText: "Close",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          //placeholder to ad any confirm functionality
        } else if (
          //placeholder to ad any cancelation functionality
          /* Read more about handling dismissals*/
          result.dismiss === Swal.DismissReason.cancel
        ) {
          //placeholder to ad any cancelation functionality outisde button
        } else {
          //placeholder for catch all
        }
      });
    } else
      MySwal.fire({
        title: <h3>{"an error occurred"}</h3>,
        html: <i>{input.error || ""}</i>,
        confirmButtonText: "close",
        icon: "error",
      });
  };

  return [fireModal];
};
export default useFireModal;
