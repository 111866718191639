import React from "react";
import { Form } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import {
  SelectAddNew,
  SelectLocaleID,
  SelectSurveyID,
  setEditMode,
} from "../../../../features/translations/translationsSlice";
import { useDispatch, useSelector } from "react-redux";
import useFireModal from "../../../../hooks/useFireModal";

import {
  useSaveUpdatedMutation,
  useSaveNewMutation,
} from "../../../../features/translations/translationsApiSlices";

const RenderItems = ({ currentItems, formData, setFormData, findRefItem }) => {

  const [fireModal] = useFireModal();

  const addNew = useSelector(SelectAddNew);
  const localeID = useSelector(SelectLocaleID);
  const surveyID = useSelector(SelectSurveyID);
  const dispatch = useDispatch();

  const [saveUpdates] = useSaveUpdatedMutation();
  const [saveNew] = useSaveNewMutation();

  const handleChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = [...formData];
    const index = formData.findIndex((item) => item.id === parseInt(fieldName));
    if (index !== -1) {
      newFormData[index].value = fieldValue;
    }
    setFormData(newFormData);
  };

  const getformItem = (id) => {
    const index = formData.findIndex((item) => item.id === id);
    if (index !== -1) {
      return formData[index].value;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (addNew === false) {
      const editedData = [...formData];
      await saveUpdates({
        editedData,
        surveyID,
        localeID,
      })
        .unwrap()
        .then((payload) => {
          fireModal(payload);
        })
        .catch((error) => {
          fireModal({}, (error = true));
        });
      dispatch(setEditMode(false));
    }

    if (addNew === true) {
      const editedData = formData.map(({ id, ...item }) => item);

      await saveNew({
        editedData,
        surveyID,
        localeID,
      })
        .unwrap()
        .then((payload) => {
          fireModal(payload);
        })
        .catch((error) => {
          fireModal({}, (error = true));
        });

      dispatch(setEditMode(false));
    }
  };

  return (
    <>
      <Form id="myform" onSubmit={handleSubmit}>
        {formData &&
          currentItems &&
          currentItems.map((item) => (
            <div className="row" key={item.id}>
              <div className="col-md-6 col-xl-3 col-xxl-6 mb-3">
                <label>Item ID</label>
                <div className="input-group clockpicker">
                  <input
                    type="text"
                    className="form-control"
                    value={item.id}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-6 mb-3">
                <label>Category</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={item.category}
                />
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-6 mb-3">
                <label>Reference Text</label>
                <TextareaAutosize
                  disabled
                  type="text"
                  className="form-control"
                  id="val-username"
                  value={findRefItem(
                    item.question_id,
                    item.survey_id,
                    item.category
                  )}
                />
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-6">
                <label>Translation Text</label>
                <TextareaAutosize
                  type="text"
                  className="form-control"
                  id="val-username"
                  name={item.id}
                  value={getformItem(item.id)}
                  onChange={handleChange}
                />
              </div>
            </div>
          ))}
      </Form>
    </>
  );
};

export default RenderItems;
