import { SERVER_LIST } from "./constant";

export function getBaseUrl() {
  const serverId = localStorage.getItem("ServerId");
  const API_SERVER = `${SERVER_LIST[serverId].value}/api/admin/v2`;
  return API_SERVER;
}

export function saveServerInLocalStorage(server) {
  localStorage.setItem("ServerId", server);
}
