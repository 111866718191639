import { apiSlice } from "../../api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => {
        return `/users/`;
      },
      keepUnusedDataFor: 600,
      providesTags: ["Users"],
    }),
    getLoggedUser: builder.query({
      query: ( id ) => {
        return `/users/${id}`;
      },
      keepUnusedDataFor: 600,
      providesTags: ["LoggedUser"],
    }),
    updateUsers: builder.mutation({
      query: (body) => ({
        url: "/users/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUsersMutation,
  useGetLoggedUserQuery,
} = usersApiSlice;
