import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import OptionsDialog from "./OptionsDialog";
import RenderItems from "./RenderItems";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAddNew,
  SelectLocaleID,
  setEditMode,
  setAddNew,
} from "../../../../features/translations/translationsSlice";

const SettingsCard = (props) => {
  const {
    currentItems,
    refItems,
    translations,
  } = props;

  const dispatch = useDispatch();
  const addNew = useSelector(SelectAddNew);
  const localeID = useSelector(SelectLocaleID);

  const [optionsDialog, setOptionsDialog] = useState(false);

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    createInitialForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createInitialForm() {
    if (addNew === false) {
      let testFields = [];
      translations.forEach((item) => {
        testFields.push({
          id: item.id,
          survey_id: item.survey_id,
          question_id: item.question_id,
          category: item.category,
          value: item.value,
        });
      });
      setFormData(testFields);
    }

    if (addNew === true) {
      let formFields = [];
      refItems.forEach((item) => {
        if (item.survey_id) {
          formFields[item.id] = {
            id: item.id,
            locale_id: localeID,
            survey_id: item.survey_id,
            question_id: null,
            category: item.category,
            value: "",
          };
        }
        if (item.question_id) {
          formFields[item.id] = {
            id: item.id,
            locale_id: localeID,
            survey_id: null,
            question_id: item.question_id,
            category: item.category,
            value: "",
          };
        }
      });
      setFormData(formFields);
    }
  }

  const findRefItem = (question_id, survey_id, category) => {
    if (survey_id != null) {
      const refItem = refItems.filter(
        (item) => item.survey_id === survey_id && item.category === category
      );
      return refItem[0].value;
    }
    if (question_id != null) {
      const refItem = refItems.filter(
        (item) => item.question_id === question_id && item.category === category
      );
      return refItem[0].value;
    }
  };

  function handlePopulate(value) {
    if (value === 0) {
      createInitialForm();
    }

    if (value === 1) {
      const newFormData = [...formData];
      newFormData.forEach((item) => {
        item.value = "";
      });
      setFormData(newFormData);
    }
    if (value === 2) {
      const newFormData = [...formData];
      newFormData.forEach((item) => {
        item.value = findRefItem(
          item.question_id,
          item.survey_id,
          item.category
        );
      });
    }
  }

  const handleCancelAction = (value) => {
    dispatch(setEditMode(false));
    dispatch(setAddNew(false));
  };

  return (
    <>
      <div className="col-xl-6 col-lg-6">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Settings</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <div className="form-group">
                <Button
                  type="submit"
                  form="myform"
                  className="me-2"
                  variant="success"
                >
                  {addNew ? "Save New" : "Save Changes"}
                </Button>
                <Button
                  className="me-2"
                  variant="warning"
                  onClick={handleCancelAction}
                >
                  Cancel
                </Button>
                <OptionsDialog
                  optionsDialog={optionsDialog}
                  setOptionsDialog={setOptionsDialog}
                  handlePopulate={handlePopulate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <RenderItems
        currentItems={currentItems}
        formData={formData}
        setFormData={setFormData}
        findRefItem={findRefItem}
      />
    </>
  );
};

export default SettingsCard;
