import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";
import {
  SelectAllSurveys,
  SelectSurveyID,
} from "../../../../features/translations/translationsSlice";
import { setSurveyID } from "../../../../features/translations/translationsSlice";

const SurveyCard = () => {
  const surveyID = useSelector(SelectSurveyID);
  const surveys = useSelector(SelectAllSurveys);
  const dispatch = useDispatch();

  const handleSurveyChange = (event) => {
    event.preventDefault();
    dispatch(setSurveyID(parseInt(event.target.value))) 
  };
  
  const handleFindDetails = (item) => {
    const survey = surveys.find((survey) => survey.id === surveyID);

    if (!survey) {
      return "----";
    }

    switch (item) {
      case "title":
        return survey.title;
      case "slug":
        return survey.slug;
      default:
        return "----";
    }
  };
  return (
    <div className="col-xl-6 col-lg-6">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Survey Selection</h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <div className="form-group mb-3">
              <select
                value={surveyID}
                onChange={(event) => handleSurveyChange(event)}
                className="form-control"
                id="sel1"
              >
                <option value={-1}>{"--- choose a survey ---"}</option>
                {surveys.length > 0 &&
                  surveys.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.title} {"("} {option.slug} {")"}
                    </option>
                  ))}
              </select>

              <div className="basic-list-group">
                <ListGroup as="ul">
                  <React.Fragment>
                    <ListGroup.Item as="li">
                      <dt>Survey Title: </dt> {handleFindDetails("title")}
                    </ListGroup.Item>
                  </React.Fragment>
                  <React.Fragment>
                    <ListGroup.Item as="li">
                      <dt>Survey slug:</dt> {handleFindDetails("slug")}
                    </ListGroup.Item>
                  </React.Fragment>
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyCard;
