
export const COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'name',
    editable: false,
  },
  {
    Header: 'Last Name',
    accessor: 'surname',
    editable: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    editable: false,
  },
  {
    Header: 'Telephone',
    accessor: 'telephone',
    editable: false,
  },
  {
    Header: 'Timezone',
    accessor: 'timezone',
    editable: false,
  },

  {
    Header: 'Joined at',
    accessor: 'date_joined',
    editable: false,
  },

  {
    Header: 'Active',
    accessor: 'active',
    disableFilters: true,
    editable: false,
    Cell: ({ value }) => String(value)
  },
  

]
