import React, { useContext } from "react";

/// Css
//import "./chart.css";
//import "./step.css";

/// Layout
import Nav from "./layouts/nav/Menu";
import Footer from "./layouts/Footer";

import { ThemeContext } from "../context/ThemeContext";

const Page = ({ component }) => {
  const { menuToggle } = useContext(ThemeContext);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            {component}
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>

    </>
  );
};

export default Page;
