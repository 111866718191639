

/// Dashboard
import Home from "../jsx/components/Dashboard/Home";
import DashboardDark from "../jsx/components/Dashboard/DashboardDark";

// User Management
import Users from "../jsx/pages/users/Users";

// Translation Management
import Translations from "../jsx/pages/translations/Translations";

/// Pages
import Registration from "../jsx/pages/auth/Registration";
import Login from "../jsx/pages/auth/Login";
import ForgotPassword from "../jsx/pages/auth/ForgotPassword";
import LockScreen from "../jsx/pages/auth/LockScreen";


const routes =  [
 /// Dashboard
{ url: "", component: <Home /> },
{ url: "dashboard", component: <Home />},
{ url: "dashboard-dark", component: <DashboardDark /> },


/// User Management
{ url: "users", component: <Users /> },

// Translation Management
{ url: "translations", component: <Translations /> },


/// pages
{ url: "page-register", component: <Registration /> },
{ url: "page-lock-screen", component: <LockScreen /> },
{ url: "page-login", component: <Login /> },
{ url: "page-forgot-password", component: <ForgotPassword /> },
  ];

export default routes;
