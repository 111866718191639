import React from 'react'
import { GlobalFilter } from './GlobalFilter'
import AddUserDialog from './AddUserDialog'

const TableToolbar = props => {
  const {
    sendDataAPI,
    addUserHandler,
    setGlobalFilter,
    globalFilter,
  } = props
  return (
    <>
    <div className="d-flex justify-content-between">
      <span>
      {/* To be reviewed and migrated
              <AddUserDialog
            addUserHandler={addUserHandler}
            sendDataAPI={sendDataAPI}
            />
      
      */}

      </span>
      <span>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </span>
    </div>

</>
  )
}


export default TableToolbar
