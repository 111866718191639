import { useDispatch, useSelector } from "react-redux";
import {
  useGetRefItemsQuery,
  useGetTranslationsQuery,
} from "../../../features/translations/translationsApiSlices";
import Loader from "../../components/Loader";
import PaginateItems from "./components/PaginateItems";
import {
  SelectAddNew,
  SelectLocaleID,
  SelectSurveyID,
} from "../../../features/translations/translationsSlice";

const Editor = () => {
  const dispatch = useDispatch();
  const addNew = useSelector(SelectAddNew);
  const localeID = useSelector(SelectLocaleID);
  const surveyID = useSelector(SelectSurveyID);

  const {
    data: translationItems,
    isLoading: translationsLoading,
    isSuccess: translationsSuccess,
    isError: isTransalationsError,
    error: translationsError,
  } = useGetTranslationsQuery({ surveyID, localeID });

  const {
    data: refItems,
    isLoading: refItemsLoading,
    isSuccess: isRefitemsSuccess,
    isError: isRefItemsError,
    error: refItemsError,
  } = useGetRefItemsQuery({ surveyID, localeID });

  if (translationsLoading || refItemsLoading) {
    return <Loader />;
  } else if (translationsSuccess && isRefitemsSuccess) {
    // here we set the contents on the translation slice

  } else {
    return (
      <>
        <p>{JSON.stringify(translationsError)}</p>
        <p>{JSON.stringify(refItemsError)}</p>
      </>
    );
  }

  return (
    <div>
      {(() => {
        switch (addNew) {
          case false:
            return (
              <>
                {translationItems.length > 0 &&
                  translationsLoading !== 1 &&
                  refItems.length > 0 &&
                  refItemsLoading !== 1 && (
                    <PaginateItems
                      itemsPerPage={8}
                      translations={translationItems}
                      refItems={refItems}
                    />
                  )}
              </>
            );

          case true:
            return (
              <>
                {refItems.length > 0 && refItemsLoading !== 1 && (
                  <PaginateItems
                    itemsPerPage={8}
                    translations={refItems}
                    refItems={refItems}
                  />
                )}
              </>
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Editor;
