import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const OptionsDialog = (props) => {
  const { optionsDialog, setOptionsDialog, handlePopulate } = props;

  const [selectValue, setSelectValue] = useState(0);

  const handlePopulateChange = (event) => {
    event.preventDefault();
    setSelectValue(parseInt(event.target.value));
  };

  const handleApplyAction = () => {
    handlePopulate(selectValue);
    setOptionsDialog(false);
  };

  return (
    <>
      <Button
        className="me-2"
        variant="primary btn-rounded"
        onClick={() => {
          setOptionsDialog(true);
        }}
      >
        <span className="btn-icon-start text-primary">
          <i className="fa fa-wrench" />
        </span>
        Options
      </Button>

      <div className="col-12">
        <Modal
          className="modal fade"
          show={optionsDialog}
          onHide={setOptionsDialog}
        >
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Options</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setOptionsDialog(false)}
                    data-dismiss="modal"
                  >
                    <span></span>
                  </button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Populate Values By
                    </label>
                    <select
                      value={selectValue}
                      onChange={(event) => handlePopulateChange(event)}
                      className="form-control"
                      id="opt1"
                    >
                      <option value={0}>
                        {"--- popultate original values ---"}
                      </option>
                      <option value={1}>
                        {" "}
                        {"--- populate empty fields ---"}
                      </option>
                      <option value={2}>
                        {" "}
                        {"--- populate by refItems ---"}
                      </option>
                      <option value={3}> {"--- populate by DeepL ---"}</option>
                      <option value={4}>
                        {" "}
                        {"--- populate by Po file ---"}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleApplyAction}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setOptionsDialog(false);
                    }}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default OptionsDialog;
