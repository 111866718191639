import { useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { COLUMNS } from "./columns";
import { EditableTable } from "../../components/data-table/EditableTable";
import { useGetUsersQuery } from "../../../features/users/usersSlice";
import Loader from "../../components/Loader";
const Users = () => {
  const columns = useMemo(() => COLUMNS, []);
  const { data, isLoading, isSuccess, isError, error } = useGetUsersQuery();
  const [skipPageReset, setSkipPageReset] = useState(true);

  const sendDataAPI = async (user, method, url) => {
    // call to API send modified data back
    let data;

    //const data = await axiosFetch({
    //    axiosInstance: axiosInstance,
    //    method: method,
    //    url: url,
    //    requestConfig : user
    //});
    return data;
  };

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    var dataset = data;
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <EditableTable
        columns={columns}
        data={data}
        //setData={setData}
        error={error}
        loading={isLoading}
        sendDataAPI={sendDataAPI}
        skipPageReset={skipPageReset}
      />
    </>
  );
};

export default Users;
