import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";

const AddUserDialog = ({ addUserHandler, sendDataAPI }) => {
  const [addCard, setAddCard] = useState(false);

  const initialContent = {
    name: "",
    surname: "",
    email: "",
    password: "",
  };

  const [addFormData, setAddFormData] = useState(initialContent);

  const handleClickOpen = () => {
    setAddCard(true);
  };

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.name === "") {
      error = true;
      errorMsg = "Please fill name";
    } else if (addFormData.surname === "") {
      error = true;
      errorMsg = "Please fill surname.";
    } else if (addFormData.email === "") {
      error = true;
      errorMsg = "please fill email";
    } else if (addFormData.password === "") {
      error = true;
      errorMsg = "please fill password";
    }
    if (!error) {
      const newContent = {
        name: addFormData.name,
        surname: addFormData.surname,
        email: addFormData.email,
        password: addFormData.password,
      };
      ////
      sendDataAPI(newContent, "POST", "/users/add").then(
        function (result) {
          // console.log(result); // "Promise resolved successfully"
          if (result.success === "True") {
            //update the userlist with the new data!
            addUserHandler(result.data);
            setAddCard(false);
            swal("Good job!", result.message, "success");
            // reset Formdata to initial values
            setAddFormData(initialContent);
          } else {
            swal("Oops", result.message, "error");
          }
        },
        (err) => {
          console.log(err); // Error: "Promise rejected"
        }
      );
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary shadow btn-xs sharp me-2"
        aria-label="add"
        onClick={handleClickOpen}
      >
        <i className="fa fa-plus"></i>
      </Button>
      <div className="col-12">
        <Modal className="modal fade" show={addCard} onHide={setAddCard}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add Contact</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setAddCard(false)}
                    data-dismiss="modal"
                  >
                    <span></span>
                  </button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Name</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            autocomplete="off"
                            name="name"
                            required="required"
                            onChange={handleAddFormChange}
                            placeholder="name"
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Surname</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            autocomplete="off"
                            name="surname"
                            required="required"
                            onChange={handleAddFormChange}
                            placeholder="surname"
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Email</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            autocomplete="off"
                            name="email"
                            required="required"
                            onChange={handleAddFormChange}
                            placeholder="email"
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">Password</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            autocomplete="off"
                            name="password"
                            required="required"
                            onChange={handleAddFormChange}
                            placeholder="password"
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleAddFormSubmit}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setAddCard(false)}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddUserDialog;
