import { apiSlice } from "../../api/apiSlice";

export const translationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query({
      query: () => {
        return `/surveys/`;
      },
      keepUnusedDataFor: 600,
      providesTags: ['Surveys'],
    }),
    getTranslations: builder.query({
      query: ({ surveyID, localeID }) => {
        return `/translations/list/?survey_id=${surveyID}&locale=${localeID}`;
      },
      keepUnusedDataFor: 600,
      providesTags: ["Translations"],
    }),
    getRefItems: builder.query({
      query: ({ surveyID, localeID }) => {
        return `/surveys/ref_items/?survey_id=${surveyID}&locale=${localeID}`;
      },
      keepUnusedDataFor: 600,
      providesTags: ["RefItems"],
    }),

    saveUpdated: builder.mutation({
      query: ({ editedData, surveyID, localeID }) => ({
        url: `/translations/update/?survey_id=${surveyID}&locale=${localeID}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ editedData }),
      }),
      invalidatesTags: ["Translations", "Surveys"],
    }),
    saveNew: builder.mutation({
      query: ({ editedData, surveyID, localeID }) => ({
        url: `/translations/add/?survey_id=${surveyID}&locale=${localeID}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ editedData }),
      }),
      invalidatesTags: ["Translations", "Surveys"],
    }),
    deleteTranslation: builder.mutation({
      query: ({ editedData, surveyID, localeID }) => ({
        url: `/translations/delete/?survey_id=${surveyID}&locale=${localeID}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ editedData }),
      }),

      invalidatesTags: ["Translations", "Surveys"],
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetTranslationsQuery,
  useGetRefItemsQuery,
  useSaveUpdatedMutation,
  useSaveNewMutation,
  useDeleteTranslationMutation
} = translationsApiSlice;
