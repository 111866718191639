import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    id: null,
    user: null,
    token: null,
    roles: [],
    activeRole: null,
    permissions: [],
  },
  reducers: {
    setCredentials: (state, action) => {
      const { id, user, accessToken, roles, permissions } = action.payload;
      function setActiveRole() {
        if (roles.includes("user_admin")) return "user_admin";
        else if (roles.includes("trustie_user")) return "trustie_user";
        else return null;
      }
      state.activeRole = setActiveRole();
      state.id = id;
      state.user = user;
      state.token = accessToken;
      state.roles = roles;
      state.permissions = permissions;
    },
    setLogout: (state) => {
      state.id = null;
      state.user = null;
      state.token = null;
      state.roles = [];
      state.activeRole = null;
      state.permissions = [];
    },
    setActiveRole: (state, action) => {
      if (state.roles.includes(action.payload)) {
        state.activeRole = action.payload;
      }
    },
  },
});

export const { setCredentials, setLogout, setActiveRole } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.id;
export const selectCurrentToken = (state) => state.auth.token;
export const getCurrentLoginStatus = (state) =>
  state.auth.token ? true : false;
export const selectCurrentRoles = (state) => state.auth.roles;
export const selectActiveRole = (state) => state.auth.activeRole;
export const selectCurrentPermissions = (state) => state.auth.permissions;
