import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { setAddNew, setEditMode, setLocaleID } from "../../../../features/translations/translationsSlice";

const AddNewDialog = (props) => {
  const { systemLocales,  surveyLocales } =
    props;


  const dispatch = useDispatch()

   
  const [addCard, setAddCard] = useState(false);
  const [selected, setSelected] = useState(-1);

  // create the list of langages suatable for creating a new one
  const listOptions = [...systemLocales];

  surveyLocales.forEach((item) => {
    const index = listOptions.findIndex((option) => option.id === item.id);
    if (index > -1) {
      listOptions.splice(index, 1);
    }
  });

  const handleClickOpen = () => {
    setAddCard(true);
  };

  const handleCancelAction = (value) => {
    setSelected(-1);
    setAddCard(false);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setSelected(parseInt(event.target.value));
  };

  const handleAddNewAction = (value) => {
    if (selected === -1) {
      swal("Oops", "please select a language to create", "error");
      return;
    }
    dispatch(setLocaleID(selected))
    dispatch(setAddNew(true))
    dispatch(setEditMode(true))

  };

  return (
    <React.Fragment>
      <button
        className="btn btn-success"
        type="button"
        variant="success"
        onClick={handleClickOpen}
      >
        {" "}
        Add New{" "}
      </button>

      <div className="col-12">
        <Modal className="modal fade" show={addCard} onHide={setAddCard}>
          <div className="" role="document">
            <div className="">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title fs-20">Add New Survey Language</h4>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setAddCard(false)}
                    data-dismiss="modal"
                  >
                    <span></span>
                  </button>
                </div>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Language</label>
                    <select
                      value={selected}
                      onChange={(event) => handleChange(event)}
                      className="form-control"
                      id="select_locale"
                    >
                      <option value={-1}>{"--- choose a Lanuage ---"}</option>
                      {listOptions.map((option, index) => (
                        <option key={index} value={[option.id]}>
                          {option.id} {"("} {option.locale} {")"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleAddNewAction}
                  >
                    Add New
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelAction}
                    className="btn btn-danger"
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AddNewDialog;
