import { lazy, Suspense } from "react";

/// Components
///import Index from "./jsx";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Loader from "./jsx/components/Loader";
import AuthGuard from "./features/auth/AuthGuard";
import PersistLogin from "./features/auth/PersistLogin";
// action

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./vendor/datatables/css/dataTables.min.css";
import "./css/style.css";
import routes from "./config/routes";

import Page from "./jsx/Page";

const SignUp = lazy(() => import("./jsx/pages/auth/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/auth/ForgotPassword"));
const Login = lazy(() => import("./jsx/pages/auth/Login"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
      <Route element={<PersistLogin />}>
        <Route element={<AuthGuard />}>
          {routes.map((data, i) => (
            <Route
              key={i}
              path={`/${data.url}`}
              element={<Page component={data.component} />}
            />
          ))}
        </Route>
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
