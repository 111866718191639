import { useEffect } from "react";

import {
  SelectSurveyID,
  setEditMode,
  setAddNew,
  SelectAllSurveys,
} from "../../../features/translations/translationsSlice";
import { useDispatch, useSelector } from "react-redux";
import SurveyCard from "./components/SurveyCard";
import ActionCard from "./components/ActionCard";
const SurveySelection = () => {
  const dispatch = useDispatch();
  const surveyID = useSelector(SelectSurveyID);
  const surveys = useSelector(SelectAllSurveys);

  useEffect(() => {
    dispatch(setEditMode(false));
    dispatch(setAddNew(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys]);

  return (
    <div className="row">
      <SurveyCard />
      {surveyID !== -1 && <ActionCard />}
    </div>
  );
};

export default SurveySelection;
