import { useState } from 'react';
import { Link} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../../features/auth/authApiSlice';
import { setLogout } from '../../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

function LogoutPage(){

    const dispatch = useDispatch();
    const [logout] = useLogoutMutation();
    const [errMsg, setErrMsg] = useState("");
    const nav = useNavigate();

    const handleLogout = async (e) => {
        e.preventDefault();
    
        try {
          //dispatch a function which performs the logout
          await logout().unwrap();
          dispatch(setLogout());
          dispatch({ type: "RESET" }); //calls the root reducer with RESET as action!
          nav("/login");
        } catch (err) {
          if (!err?.originalStatus) {
            // isLoading: true until timeout occurs
            setErrMsg("No Server Response");
          } else if (err.originalStatus === 400) {
            setErrMsg("General error");
          } else if (err.originalStatus === 401) {
            setErrMsg("Unauthorized");
          } else {
            setErrMsg("Logout Failed");
          }
        }
      };

    return(
        <>
            <Link  className="dropdown-item ai-icon" onClick={handleLogout}>
                <svg
                  id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                  className="text-danger" width={18} height={18} viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2" >Logout </span>
            </Link>
        </>
    )
}

export default LogoutPage;
