import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import { useGetLoggedUserQuery } from "../../../features/users/usersSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth/authSlice";

const Header = () => {
  const uid = useSelector(selectCurrentUser);
  const { data } = useGetLoggedUserQuery(uid);

  let path = window.location.pathname.split("/");
  let name = path[path.length - 1].split("-");
  let filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {filterName.join(" ").length === 0
                  ? "Dashboard"
                  : filterName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : filterName.join(" ")}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                >
                  <h1>
                    <i className="bi bi-person"></i>
                  </h1>
                  {data && (
                    <div className="header-info ms-3">
                      <span>
                        {data.name} {data.surname}
                      </span>
                      <small>Superuser</small>
                    </div>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
