import React from "react";

const Editable = ({
  row,
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleEditFormSubmit,
}) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (cell.column.editable === true) {
          return (
            <td key={i} {...cell.getCellProps()}>
              <input
                type="text"
                required="required"
                placeholder="Fill in"
                name={cell.column.id}
                value={editFormData[cell.column.id]}
                onChange={handleEditFormChange}
              />
            </td>
          );
        } else {
          return <td {...cell.getCellProps()}> {cell.render("Cell")}</td>;
        }
      })}
      {/* To be review  for future use after upgrades
       <td>
       <div className="d-flex">
       <button className="btn btn-warning shadow btn-xs sharp me-1" type="submit"><i className="las la-check-circle scale5"></i></button>
       <button className="btn btn-danger shadow btn-xs sharp " type="button" onClick={handleCancelClick}>
         <i className="las la-times-circle scale5"></i>
       </button>
       </div>
           </td>

    */}
    </tr>
  );
};
export default Editable;
