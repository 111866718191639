import React, { useContext, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { ThemeContext } from "../../../context/ThemeContext";

import Metismenu from "metismenujs";

const MM = ({ children }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const mm = new Metismenu(menuRef.current);
    return () => {
      mm.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" ref={menuRef}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const scrollPosition = useScrollPosition();

  // Path
  let path = window.location.pathname.split("/").pop();

  // Active menu
  const deshBoard = ["", "dashboard-dark"];
  const users = ["users"];
  const translations = ["translations"];

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "" ? "mm-active" : ""}`}
                  to="/dashboard"
                >
                  Dashboard Light
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "dashboard-dark" ? "mm-active" : ""}`}
                  to="/dashboard-dark"
                >
                  Dashboard Dark
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <span className="nav-text">Users & Roles</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "users" ? "mm-active" : ""}`}
                  to="/Users"
                >
                  Users
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${translations.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">

              <span className="nav-text">Translations</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "translations" ? "mm-active" : ""}`}
                  to="/translations"
                >
                  Management
                </Link>
              </li>
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
