import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  //the debounce funtion creates a delay so not every event wil result in a Filter action
  const handleChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        className="ml-2 input-search form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
        placeholder="Search"
      />
    </span>
  );
};
