import { useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { useGetSurveysQuery } from "../../../features/translations/translationsApiSlices";
import {
  SelectEditMode,
  setSurveys,
} from "../../../features/translations/translationsSlice";
import Loader from "../../components/Loader";
import SurveySelection from "./Surveyselection";
import Editor from "./Editor";

const Translations = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, isError, error } = useGetSurveysQuery();
  const editMode = useSelector(SelectEditMode);
  
  useEffect(() => {
    if (isSuccess) {
      dispatch(setSurveys(data));
    }
  }, [isSuccess, data, dispatch]);


  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return <>{editMode ? <Editor /> : <SurveySelection />}</>;
};

export default Translations;
